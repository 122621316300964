.bookingsystem_container{
    background-image: url(../../assets/clock_corner_beige.jpg);
    
    background-repeat: no-repeat;
    background-position: right;
}

.tracking-in-contract {
	-webkit-animation: tracking-in-contract 0.8s ease-in both;
	        animation: tracking-in-contract 0.8s ease-in both;
}

.clock
{
  /*-webkit-transform: scaleX(-1);
  transform: scaleX(-1);*/
  opacity: 0.8;
}

.text_system
{
  width:80%;
  vertical-align: top;
}

#bookingsystem{
  
  width:100%;
  padding-top: 10px;
  
}

@-webkit-keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}
@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}


