
html {
  --button-backgroundcolor: #4D7E3E;
  --line-color:#4D7E3E;
  --outline-color-dark: #1e3119;
  --outline-color-light: #7bce62;
  --button-text-color: white;
  --link-color:#4D7E3E;
  --headline-color:#4D7E3E;
  --button-border-color:darkgreen;
  --site-backgroundcolor:#fffbf2;
}

h1{
  color:var(--headline-color);
  text-shadow: 5px 5px 10px gray;
}

.main_container {
  padding-top: 0px;
  padding-left:10px;
  padding-right:10px;
  padding-bottom:10px;
  margin-top:86px;
  background-color:  var(--site-backgroundcolor);
}

.hr-line
{
    color: var(--site-backgroundcolor);
    background-color:var(--line-color);
    align-items: stretch;
    margin:0px 0px 0px 0px; 
    height: 0.75em;
    line-height: 1em;
    /*background: linear-gradient(to right, white,var(--line-color));*/
    background: linear-gradient(to left, var(--site-backgroundcolor),var(--line-color));
     
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {

  background-color: var(--button-backgroundcolor) !important;
  border-color: var(--button-border-color) !important;
  
  /*border-style: solid !important;*/
  color:var(--button-text-color) !important;
  text-shadow: 5px 5px 10px black !important;
}
