.Header
{
    position: fixed;        
    background-color: var(--site-backgroundcolor);
    top: 0;
    width:100%;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    z-index: 10000; /*nu ligger den överst*/
}
