#logo{
  height:44px;
}
#logo_text
{
  height:38px;
}

#circle_logo{
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: green;
  /*box-shadow: 0 0 1px 0 #71B47A;*/
  clip: rect(0px, 20px, 20px, 0px);
}

#circle_logo2{
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: red;
  /*box-shadow: 0 0 1px 0 #b47971;*/
  clip: rect(0px, 20px, 20px, 10px);
}

#circle_logo3{
  margin-left:5px;
  margin-top: 5px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background-color: black;
  box-shadow: 0 0 1px 0 #0d0e0d;
 
}

.rotate{
  animation: rotate 10s linear infinite; 
}


@keyframes rotate{
  from  {
    transform: rotate(0deg);
 }
 to {
    transform: rotate(360deg);
  }	
}

.rotate2{
  animation: rotate 60s linear infinite; 
}


@keyframes rotate2{
  from  {
    transform: rotate(0deg);
 }
 to {
    transform: rotate(360deg);
  }	
}

